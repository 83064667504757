/* error
========================================================================== */
 
.erreur{

    @media screen and (max-width: 992px){ 
        margin-top: 86px;
    }


    h1{
        font-size: clamp(2.1rem, 5vw ,3rem);

        &:before{
            content: '';
            width: 5rem;
            height: .4rem;
            display: block;
            background: $secondary;
            margin-bottom: .5rem;
        }
    }

    .status-code{
        @extend h1;
        font-size: clamp(7rem, 5vw ,12rem);
        &:before{
            display: none;
        }
    }

    .container{
        display: flex;
        align-items: center;
        height: 100%;  
    }

    .error-content .container{
        min-height: calc(100vh - 159px);
    }

    .error-content{
        position: relative;
        height: 100%;

        @media screen and (max-width: 767px){ 
            display: grid;

            .container{
                order: 1;
            }

            picture{
                max-height: 250px;
            }
        }

        @media screen and (min-width: 768px){ 
            picture{
                position: absolute;
                right: 0;
                top: 0;
                width: 50%;
            }
        }

    }



    img{
        width: 100%;
        height: 100%;
    }

}