/* Menu
   ========================================================================== */
.menu-main{
    font-family: $font-family-monospace;
    position: sticky;
    z-index: 999;

    .navbar-brand{
        max-width: 190px;
    }

    .navbar-toggler{
        border: none;
        padding: 0;

        &:focus{
            box-shadow: none;
        }
    }
    
    .navbar-toggler-icon{
        mask-image: var(--bs-navbar-toggler-icon-bg);
        background: white
    }

    .nav-link{
        color: white;
    }

}