/* Overlay
   ========================================================================== */


.overlay{
    position: relative;
    
    &__image{
        @extend .images--full;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__block{
        @extend .overlay__image;
        display: block;
        width: 100%;
        height: 100%;
        // @extend .bg-color3-500;
        opacity: .75;


        & ~ *{
            position: relative;
            z-index: 10;
        }
    }
}