/* Custom
========================================================================== */

.features-text-image__col--image{
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    .picture{
        border: 1rem solid white;
        box-shadow: 1rem 1rem 1rem #00000020;
        position: relative;

        &:before{
            content: '';
            width: 80%;
            height: 80%;
            background: $secondary;
            display: block;
            position: absolute;
            z-index: -1;
            right: clamp(-8vw, 5vw, -2rem);
            bottom: clamp(-8vw, 5vw, -2rem);
        }
    }
}
