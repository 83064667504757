/* _offres
========================================================================== */
 
.offres{
    

    
    p{
        margin:0;
    }
    
    .link{
        color: $primary;
    }
    
    .card{
        border: none;
        box-shadow: 5px 5px 2rem #00000020;

        &-title{
            color: $secondary;
        }

        &-body{
            height: 100%;
            width: 80%;
            margin: auto;
        }


        &:hover{
            background-color: $secondary;
            transition: $transition;
            color: white;

            .card-title{
                color: $primary;
                transition: $transition;
            }

            .link{
                color: currentColor;
                transition: $transition;
                border-bottom: 0.4rem solid $primary;
            }

        }
    }
}