/* _accordion
========================================================================== */

.accordion{
    padding: 2rem 1rem;
    background: white;

    @media screen and (min-width: 1024px){ 
        padding: 3rem;
    }

    &__link{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: row;
        gap: 1rem;

        &[aria-expanded="false"]{
            i{
                transition: transform .5s ease-in-out;
                transform: rotate(180deg);
            }
        }

        &[aria-expanded="true"]{
            i{
                transition: transform .5s ease-in-out;
                transform: rotate(0deg);
            }
        }
    }

    i{
        font-size: 1.5rem;
    }

    .card,
    .card-header{
        background: none;
        padding: .5rem;
        border: none;

        @media screen and (min-width: 1024px){ 
            padding: 1rem;
        }
    }

    &__title{
        margin: 0;
        line-height: 1;
        font-family: $font-family-monospace;
        color: $primary;
    }

    .card-body{
        align-items:flex-start;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .list{
        margin: 0;
    }
}