/* _citation
========================================================================== */

.citation{
    border: 1px solid white;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    figcaption{
        color: $secondary;
    }

    cite{
        color: $light;
    }
}