/* Images
   ========================================================================== */

picture{
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.images,
.picture{

    &--cover{
        object-fit: cover;
    }

    &--contain{
        object-fit: contain;
    }

    &--full{
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &--H-full{
        object-fit: cover;
        width: 100%;
    }

    &--contain{
        object-fit: contain;
        width: 100%;
        height: auto;
    }
}