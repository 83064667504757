/* _home
========================================================================== */


    .slider-actus{
        &__content{
            padding-top: 3rem;
            padding-bottom: 3rem;
        }

        .splide__track {
            height: 100vh;
            max-height: 1080px;
        }
    }

    #home{
        min-height: 600px;
        display: flex;
        align-items: center;

        .container > *{
            text-align: center;
        }

        p{
            font-size: clamp(1.5rem, 5vw, 2rem);
            color: $secondary
        }
    }


#home .images--contain{
    max-width: 240px;
}