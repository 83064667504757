/* Breadcrumbs
   ========================================================================== */

// .breadcrumbs{
//     margin-top: 1rem;
// }

// .breadcrumbs__list {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     padding: 0;
//     text-transform: uppercase;
//     font-family: $font-2;
//     font-size: 1.2rem;

//     & :first-child{
//         padding-left: 0;   
//     }
// }

.breadcrumb-item {
    @extend .text-light;
    text-transform: uppercase;


}

// .breadcrumbs__links.link{
//     color: white;
// }

// .breadcrumbs .link{
//     margin-top: 1rem;
// }