/* Map
   ========================================================================== */

#map{

    width: 100%;
    height: 260px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    & ~ * {
        margin-top: 140px;
    }

    @media screen and (min-width: 768px){ 
        width: 50%;
        height: 100%;

        & ~ * {
            margin: 0;
            padding-left: 3rem;
        }
    }
}