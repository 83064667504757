/* socialBarre
   ========================================================================== */

$transition: all .2s ease-in-out;

.socialBarre{

    &__content{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
    }

    &__icones{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        background: var(--bs-secondary);
        width: 50px;

        i{
            color: $primary;
        }
        
        &:hover,
        &:focus{
            transition: $transition;
            transform: translateY(-3px);

            i{
                transition: $transition;
                color: $light;
            }
        }
        
    }
}