
/* Lists
   ========================================================================== */

ul,
.list{
    list-style: square;
    padding-left: 1rem;
    list-style-position: inside;
}

li::marker{
    color: $secondary;
}
