/* _results
========================================================================== */
 
#page-05{
    .form-control:not(.navbar .form-control){
        @media screen and (min-width: 992px){ 
           min-width: 400px;
        }
    }

    .card{
        min-height: 100%;
        
        &-title{
            display: flex;
        }
    }

}