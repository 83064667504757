/* _masonry
========================================================================== */

$width-init: 108px;
$height-init: 320px;
$gap-init: 20px;
$directions: 
    'w' 'width',
    'h' 'height';

.masonry{
    
    &__item{

        width: $width-init;
        height: $height-init;
        margin-bottom: 20px;
        max-width: 100%;

        @each $direction, $value in $directions {
            @for $i from 1 through 12 {
                &--#{$direction}#{$i}{ 

                    @if $value == 'width' {
                        #{$value}: $width-init * $i;
                    }
                    @else{
                        #{$value}: ($height-init * $i) + ($gap-init * $i) - $gap-init ;
                    }
                }
            }
        } 
        
        .card{
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    .card{
        height: 100%;

        &:hover{
            &.bg-primary{
                background-color: $secondary !important;
            }
            
            .overlay__block.bg-primary{
                background-color: $secondary !important;
            }

            .card-title{
                color: $primary;
            }

            .tags{
                border-color: white;
            }
        }
    }

    @media screen and (max-width: 767px){ 
        .masonry__item--w4{
            width: 100%;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px){ 
        .masonry__item--w4{
            width: 50%;
        }

        .masonry__item--w6{
            width: 100%;
        }
    }

    @media screen and (max-width: 1023px){ 
        .masonry__item--h2{
            height: $height-init;
            width: 100%;
        }
    }

}