html,
body {
  height: 100%;
}

body{
    overflow-x: hidden;
    // min-height: 100vh;
    // display: grid;
    // grid-template-rows: auto 1fr auto;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

header{
    
    @media screen and (max-width: 992px){ 
        margin-top: 86px;
    }
}

@import './typographie';

.bg-linear{
    background: linear-gradient(#EEF7FF, #FFF);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 1250px;
}