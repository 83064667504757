/* _table
========================================================================== */
.table thead{

    th{
        background-color: $primary;
        color: white;
        padding: 1rem;
        font-family: $font-family-monospace;
        font-weight: 300;
    }
}


td,
th{
    vertical-align: middle;

    .tags{
        margin: auto;
    }

    &.min-width{
        min-width:300px;
    }
}

.table > :not(caption) > * > *{
    padding: 0.5rem 0.8rem;
}