/* features-text-image.scss
========================================================================== */

.features-text-image{
    
    display: grid;
    gap: 2rem;

    @media screen and (min-width: 768px){ 
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__col{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        padding: 1rem;

        &--text{
            @extend .features-text-image__col;
        }

        &--image{
            @extend .features-text-image__col;
            margin-top: auto;
            margin-bottom: auto;
        }
    }


    .picture--full{
        max-height: 500px;

        @media screen and (min-width: 768px){ 
            max-height: 700px;
        }
    }

    &--variant{
        padding-bottom: 5rem;

        @media screen and (min-width: 768px){ 
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        @media screen and (min-width: 1024px){ 
            grid-template-columns: repeat(2, minmax(0, 1fr));

            .features-text-image__col--text{
                padding-top: 5rem;
                
            }
        }
        .features-text-image__col--image .picture{
            border: none;
        }

        .features-text-image__col--image{
            margin-top: 3rem;
            @media screen and (min-width: 1024px){ 
                margin-top: auto;
            }
        }
    }
}