/* _articles
========================================================================== */
 
#page-01{
    .features-grid-images{
        max-height: initial;
    }

    .features-grid-images__col{
        @media screen and (min-width: 640px) and (max-width: 767px){ 
           &[data-span="2"]{
                grid-column: span 3/span 3;

                &[data-row="2"]{
                    grid-row: span 1/span 1;
                    grid-column: span 6/span 6;
                }
           }
           &[data-span="4"]{
                grid-column: span 6/span 6;
           }
        }
    }
}