.form-check-input{

    width: 20px;
    height: 20px;
    border: 2px solid $primary;

    &:checked{
        background-color: $secondary;
    }

}