/* _tags
========================================================================== */

.tags{
    font-family: $font-family-monospace;
    padding: .5rem 1rem;
    border: .2rem solid $secondary;
    width: max-content;
    text-transform: uppercase;
    color: currentcolor;
    
    &-noBorder{
        border: none;
        color: white;
    }

    &.qualif-sup,
    &-orange{
        @extend .tags-noBorder;
        background-color: rgb(255, 191, 0);
    }

    &.non-qualif,
    &-red{
        @extend .tags-noBorder;
        background-color: #b70000;
    }

    &.qualif,
    &-green{
        @extend .tags-noBorder;
        background-color: rgb(2, 147, 26);
    }

}