
/* Paginations
   ========================================================================== */

.pagination{
    font-family: $font-family-monospace;

    .page-link{
        color: $primary;
        border: none;   

        &:hover,
        &.active{
            background: $secondary;
            color: $light;
        }
    }
}