/* card
   ========================================================================== */

$transition:  all .3s ease-in-out;

.card{
    overflow: hidden;

    &-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    &-title,
    &-text{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        max-width: 75%;
    }

    &-title{
        font-family: $font-family-monospace;
        text-transform: uppercase;
        margin-top: 1rem;
        margin-bottom: 1rem;
        line-height: 2rem;
    }

    &:hover{

        .overlay__image,
        .card-title{
            transition: $transition;
        }

        .overlay__image{
            transform: scale(1.2);
        }

        .card-title{
            color: $secondary;
        }

    }
}

