/* Copyright
   ========================================================================== */

.footer .copyright{
    color: white;
    background: #020A1B;
    position: relative;

    .container{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;

        & > :first-child,
        & > :last-child{
            flex:1;
        }
    
        & > :last-child{
            text-align: end;
        }
    }

    a,
    .link{
        color: $light-1;
    }

    #topButton{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin: .3rem;
        order: -1;
    }

    @media screen and (min-width: 640px){ 

        .container{
            flex-direction: row;
            justify-content: space-between;
        }

        #topButton{
            order: initial;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}