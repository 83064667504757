/* Typographie
   ========================================================================== */
$fontsVariantes: (
    1: $font-family-sans-serif,
    2: $font-family-monospace,
) !default;

$fontSize: (
    xs:   0.75rem,
    sm:   0.85rem,
    base: 1.00rem,
    lg:   1.12rem,
    xl:   1.25rem,
    2xl:  1.50rem,
    3xl:  1.85rem,
    4xl:  2.25rem,
    5xl:  3.00rem,
    6xl:  3.75rem,
    7xl:  4.50rem,
    8xl:  6.00rem,
    9xl:  8.00rem,
    10xl:  9.00rem,
)!default;

$fontWeight: (
    'thin':         100,
    'extralight':   200,
    'light':        300,
    'normal':       400,
    'medium':       500,
    'semibold':     600,
    'bold':         700,
    'extrabold':    800,
    'black':        900
);
$AlignX: 'left', 'center', 'right', 'justify', 'start', 'end';

$textTransform: 'uppercase', 'lowercase', 'capitalize', 'normal-case';


@each $name, $value  in $fontsVariantes {
    .font-#{$name}{
        font-family: #{$value}
    }
}


@each $value in $AlignX {
    .align-#{$value}{
        text-align: #{$value};
    }
}



@each $name, $value in $fontSize {
    .text-#{$name}{
        font-size: #{$value};
    }
}

@each $name, $value in $fontWeight {
    .font-#{$name}{
        font-weight: #{$value};
    }
}

@each $value in $textTransform {
    .#{$value}{
        text-transform: #{$value};
    }
}

h1{
    font-family: $font-family-monospace;
    font-size: clamp(2.5rem, 5vw ,6.5rem);
}

h2{
    font-family: $font-family-monospace;
    font-size: clamp(2.1rem, 5vw ,3rem);
    margin-bottom: 2rem;

    &:before{
        content: '';
        width: 5rem;
        height: .4rem;
        display: block;
        background: $secondary;
        margin-bottom: .5rem;
    }
}

.text-center h2{
    &:before{
        margin-left: auto;
        margin-right: auto;
    }
}

h2.text-primary {
    &:before{
        background: white;
    }
}