/* Footer
   ========================================================================== */

.footer{
    flex-shrink: 0;
    color: $light;
    font-family: $font-family-monospace;

    &__logos{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    }

    .logo{
        max-width: 220px;
        object-fit: contain;
    }

    @media screen and (min-width: 640px){ 
        &__logos{
            flex-direction: row;

            & > :last-child{
                // max-width: 350px;
                max-width: 150px;
            }
        }
    }

    a,
    .link{
        color: $light;

        &:hover,
        &:focus{
            color: $secondary;
        }
    }

    ul.list{
        list-style-position: outside;

        & > * {
            padding-bottom: .5rem;
        }
    }
    
}