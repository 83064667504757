@use "sass:math";
@use "sass:color";
@use "sass:map";
@use "sass:string";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$primary: #040E24;
$secondary: #28BCEE;
$light: #CEEEFF;
$light-1: #90C7E5;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "light": $light,
    "light-1":$light-1
);

$enable-rounded: false;
$font-family-sans-serif: 'Overpass', sans-serif;
$font-family-monospace: 'Fjalla One', sans-serif;


@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.container{
    @media screen and (max-width: 575px){ 
        max-width: 100vw;
    }
}

@import "./base";
@import "./components";
@import "./layout";
@import "./pages";
@import "./theme/custom";


