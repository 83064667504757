/* features-grid-images
   ========================================================================== */

$gap-init: 1rem;
$height-init:500px;

.features-grid-images{
    display: grid;
    gap: $gap-init 0;
    max-height: inherit;

    @media screen and (max-width: 639px){ 
        img{
            max-height: 500px;
        }
    }

    @media screen and (min-width: 640px){ 
        @for $i from 1 through 12 {
            &[data-col="#{$i}"]{
                gap: $gap-init;
                grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
            }
        }
    }

    &__col{
        @extend .features-grid-images;

        @media screen and (min-width: 640px){ 
            @for $i from 1 through 12 {

                &[data-span="#{$i}"]{
                    grid-column: span #{$i} / span #{$i};
                }

                &[data-row="#{$i}"] .picture{
                    grid-row: span #{$i} / span #{$i};    
                }

                &[data-row="#{$i}"]{
                    grid-row: span #{$i} / span #{$i};    
                }
            }
        }
    }
}
