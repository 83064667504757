/* Slider
   ========================================================================== */

.slider{

    .splide__pagination{
        bottom: -2rem;
    }
    
    .splide__pagination__page{
        opacity: 1;
        border-radius: 0;
        height: .3rem;
        width: 2rem;
        background: $primary;

        &.is-active{
            transform: scale(1);
            background: $secondary;
        }
    }

    .splide__slide{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 768px){ 
            display: initial;
            flex-direction: row;
        }
    }

    &-actus{
        position: relative;
        width: 100vw;

        .splide__pagination{
            position: absolute;
            top: 0;
            height: max-content;
            width: max-content;
            display: flex;
            flex-direction: column;
            top: 50%;
            transform: translateY(-120%);
    
            @media screen and (min-width: 768px){ 
                top: 100%;
            }
    
            @media screen and (min-width: 1536px){ 
                left: 50%;
                transform: translate(-1820%,-120%);
            }
        }


        .splide__pagination__page{
            background: white;
            height: 2rem;
            width: .3rem;
        }

        .row{
            display: flex;
            align-items: center;
            height: 100%;
        }

        &__picture{
            overflow: hidden;

            @media screen and (min-width: 768px){ 
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                width: 50%; 
                height: auto; 
            }
        }

        &__content{
            padding-top: 9rem;
            padding-bottom: 9rem;
            padding-right: 4rem;
            padding-left: 4rem;
        }

        &__position{
            max-width: 653px;

            &--ml{
                @extend .slider-actus__position;
                margin-left: auto;
            }

            &--mr{
                @extend .slider-actus__position;
                margin-right: auto;
            }
        }

        &__category{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            gap: 1rem;
            padding: 0;
            list-style: inherit;
        }

        &__title:before{
            display: none;
        }
    }
    &-partner{
        img{
            max-height: 130px; 
            max-width: 100px;
            margin: .2rem;
        }

        .splide__slide{
            display: flex;
        }

        .slider__content{
            padding: 0 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: .5rem;

            & > *{
                width: 100%;
            }

            @media screen and (max-width: 767px){ 
                text-align: center;
            }

            @media screen and (min-width: 768px){ 
                flex-direction: row;
            }
        }
    }
}