/* _buttons
========================================================================== */
 
.btn{
    text-transform: uppercase;
    font-family: $font-family-monospace;
    padding: .45rem 1.4rem;
    font-size: 1.2rem;
    color: white;

    &.link{
        padding: 0;
        position: relative;
        border-bottom: .4rem solid $secondary;
        line-height: 2.5rem;
    }
}

.btn-group{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    > .btn{
        flex: initial;
    }
}