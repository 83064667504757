/* _lastArticle
========================================================================== */
 
.lastArticle{

    .features-grid-images[data-col="3"]{
        grid-template-columns: repeat(1, minmax(0, 1fr));
        max-height: initial;
        gap: 1rem 0;

        @media screen and (min-width: 992px){ 
            grid-template-columns: repeat(3, minmax(0, 1fr));
            max-height: 600px;
            gap: 1rem;
        }
    }

    
    // .features-grid-images__col[data-row="2"]{
    //     .card .btn{
    //         font-size: 1rem;
    //     }
        
    //     // .btn{
    //     //     font-size: 1rem;
    //     // }
    // }
}