/* Partner
   ========================================================================== */

.partners{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem 4rem;
    
    @media screen and (min-width: 640px){ 
        grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
    }

    @media screen and (min-width: 1280px){ 
        grid-template-columns: repeat(4, 1fr);
    }
}

.partner{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    text-align: center;
    height: 100%;

    &__title{
        text-transform: uppercase;
        margin: 1rem 0;
        font-family: $font-family-monospace;
        flex: 1;
    }

    img{
        max-height: 150px;
        max-width: 150px;
        min-height: 150px;
        min-width: 150px;
        object-fit: contain;
    }
}