/* _dropdown
========================================================================== */

.dropdown{
    

    &-menu{
        background:$primary;
        color: white;
        padding: 0;
        border: none;
        margin: 0;

        // & > li:first-child{
        //     padding-top: 1rem;
        // }

        // & > li:last-child{
        //     padding-bottom: 1rem;
        // }
    
    }

    &-item{
        color: currentColor;
        padding: 0.7rem 1.5rem;
    }
}