/* Forms
   ========================================================================== */


.form{

    .form-control{
        border: .2rem solid $secondary;
        background: transparent;
        font-weight: 300;
    }

    @import 'forms__checkbox';

    &--light{
        .form-control{
            border-color:white;
        }

        .form-control,
        .form-control::placeholder{
            color: $primary
        }
    }
}

.search{
    margin-left: 1rem;

    @media screen and (min-width: 640px){ 
        width: 300px;
    }

}


form input[type="search"]{
    border: none;
    text-transform: uppercase;
    padding: 0.8rem 1rem;
    background-color: #28BCEE5E;
    position: relative;

    &::placeholder{
        color: $primary;
    }

    &:focus::placeholder{
        color: $primary;
    }

    ~ .icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 65px;
        border: none;
    }
}

.navbar .form-control{
    mix-blend-mode: screen;

    &::placeholder{
        color: $light;
    }
}











